import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {httpOptions} from '../../http';


@Injectable({
    providedIn: 'root'
})
export class PontoService {

    constructor(private http: HttpClient) { }
    headerPonto = new EventEmitter<any>();

    ponto(ponto) {
        this.headerPonto.emit(ponto);
    }

    listPonto(id): Observable<any[]> {
        return this.http
            .get(`${environment.api}/ponto/` + id, httpOptions)
            .map(response => response['data']);
    }

    listLastInfoGateway(numeroGateway): Observable<any[]> {
        return this.http
            .get(`${environment.api}/memoria-massa-info/` + numeroGateway, httpOptions)
            .map(response => response['data']);
    }

    listPontos(clienteId): Observable<any> {
        return this.http
            .get(`${environment.api}/pontos/` + clienteId, httpOptions)
            .map(response => response)
    }

    listPontosByGrupo(grupoId): Observable<any[]> {
        return this.http
            .get(`${environment.api}/pontos-grupo/` + grupoId, httpOptions)
            .map(response => response['data']);
    }

    deletePonto(param): Observable<any[]> {
        return this.http
            .delete(`${environment.api}/ponto-delete/` + param, httpOptions)
            .map(response => response['data']);
    }

    savePonto(param): Observable<any[]> {
        return this.http
            .post(`${environment.api}/ponto`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

    updatePonto(param): Observable<any[]> {
        return this.http
            .put(`${environment.api}/ponto`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

    verificaDuplicarPonto(param): Observable<any[]> {
        return this.http
            .post(`${environment.api}/verifica-duplicar-ponto`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

}
