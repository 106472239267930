<div
	*ngIf="modalColumnExport"
	class="modal fade"
	id="modalColumnExport"
	data-bs-backdrop="static"
	data-bs-keyboard="false"
	tabindex="-1"
	role="dialog"
	aria-labelledby="modalColumnExport"
	aria-hidden="true"
>
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button
					type="button"
					class="close"
					(click)="hideModal()"
					aria-label="Close"
				>
					<span aria-hidden="true">×</span>
				</button>
				<h4 class="modal-title">Colunas para exportação</h4>
			</div>
			<div class="modal-body" style="padding-top: 0px">
				<div class="row">
					<div
						class="col-lg-3"
						style="white-space: nowrap"
						*ngFor="let col of colunas"
					>
						<label>&nbsp;</label>
						<div
							class="checkbox"
							style="margin-top: 0px; margin-bottom: 0px"
						>
							<label>
								<input
									(change)="pushExport(col, $event)"
									style="height: 15px !important"
									class="checkbox"
									type="checkbox"
									checked
								/>
								{{ col.show_name }}
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button
					type="button"
					class="btn btn-secondary"
					(click)="hideModal()"
				>
					Cancelar
				</button>
				<button type="button" class="btn btn-primary" (click)="xls()">
					XLS <i class="fa fa-download" aria-hidden="true"></i>
				</button>
				<button type="button" class="btn btn-primary" (click)="csv()">
					CSV <i class="fa fa-download" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</div>
</div>
