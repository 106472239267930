import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pontosInfo'
})
export class pontosInfoPipe implements PipeTransform {

  transform(ponto: any): String {

    const id = `ID: ${ponto.numero_medidor}`
    const version = `Versão: ${ponto.version}`
    const con = ponto.tipo_con == 'W' ? `Conexão: Wifi` : `Conexão: GSM`
    const sinalWifi = ponto.tipo_con == 'W' ?
      ponto.sig >= -30 ? `100%` : `${((-30 / ponto.sig) * 100).toFixed(0)}%` :
      ponto.sig >= 30 ? `100%` : `${((ponto.sig / 30) * 100).toFixed(0)}%`
    const bateria = ponto.bateria >= 4.2 ? 100 : ((ponto.bateria - 3.9) / 0.3) * 100
    const solar = (ponto.voltagem_solar / 200) * 100
    const ultDem = (new Date(ponto.dthr_equip)).toLocaleString('en-GB')

    return `${id} | ${version} | ${con} ${sinalWifi} | Bateria: ${bateria.toFixed(0)}% | Solar: ${solar.toFixed(0)}% | Data última demanda: ${ultDem}`
  }

}
