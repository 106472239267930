import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[appUpperCase]",
})
export class UpperCaseDirective {
	constructor(private el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event: any) {
		const initialValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initialValue.toUpperCase();
	}
}
