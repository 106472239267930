import { Component, OnInit } from "@angular/core";
import { dateOptions } from "../../utils/date.util";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	public appVersion = "2.1.0";
	public appDateVersion = `Data de atualização: ${new Date(
		"2024-09-27 00:00"
	).toLocaleDateString("pt", dateOptions)}`;
	constructor() {}

	ngOnInit() {}
}
