<div class="back1">
	<div class="back fosco w-full flex flex-col items-center justify-center">
		<div class="text-center">
			<div class="powered-icon">
				<img
					src="../../../assets/img/logo_branca_top.png"
					width="180"
				/>
			</div>
		</div>

		<form [formGroup]="loginForm" class="w-full body-login mb-[5rem]">
			<div>
				<div class="form-icon">
					<i class="fa fa-user"></i>
					<input
						type="text"
						class="form-control"
						placeholder="Usuário"
						formControlName="usuario"
					/>
				</div>
			</div>

			<div>
				<div class="form-icon">
					<i
						[ngClass]="{
							'fa-eye': !showPass,
							'fa-eye-slash': showPass
						}"
						class="fa"
						(click)="changePassView()"
						title="Mostrar/Esconder senha"
					></i>
					<input
						#passEye
						type="password"
						class="form-control"
						placeholder="Senha"
						formControlName="senha"
					/>
				</div>
			</div>

			<div>
				<button
					type="button"
					id="entrar"
					(click)="login(this.loginForm.value)"
					class="btn btn-primary btn-sm"
					[disabled]="!this.loginForm.valid"
				>
					Entrar
				</button>
			</div>

			<div style="margin-top: 5%">
				<span style="float: left"
					><a style="color: #fff" href routerLink="/redefinir-senha"
						>Esqueceu sua senha?</a
					></span
				>
			</div>
		</form>
	</div>
</div>
