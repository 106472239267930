import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "calculaDemanda",
})
export class CalculaDemandaPipe implements PipeTransform {
	transform(binario: string, fatores: Array<any>): string {
		const demanda = parseInt(binario, 2);
		let fator = parseFloat(fatores[0]);
		let perdaTransformacao =
			fatores[1] && fatores[1] > 0 ? parseFloat(fatores[1]) / 100 : 0;
		fator = fator + fator * perdaTransformacao;
		const valor = demanda * fator;

		return valor.toFixed(2);
	}
}
