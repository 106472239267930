import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { swalError } from "../utils/swal-toast.utils";

@Injectable({
	providedIn: "root",
})
export class AplicationErrorHandler extends ErrorHandler {
	constructor(private injector: Injector, private zone: NgZone) {
		super();
	}

	handleError(errorResponse: HttpErrorResponse) {
		this.zone.run(() => {
			// super.handleError(errorResponse)
			const router = this.injector.get(Router);
			if (errorResponse instanceof HttpErrorResponse) {
				switch (errorResponse.status) {
					case 401:
						swalError("Não autenticado!");
						break;
					case 403:
						router.navigate(["/*"]);
						swalError("Não autorizado!");
						break;
					case 404:
						swalError("Recurso não encontrado!");
						break;
				}
			}
		});
	}
}
