import { Component, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { swalError, swalSuccess } from "../../utils/swal-toast.utils";
import { RedefinirSenhaService } from "../redefinir-senha/redefine-senha.service";
declare var $: any;

@Component({
	selector: "app-nova-senha",
	templateUrl: "./nova-senha.component.html",
	styleUrls: ["./nova-senha.component.scss"],
})
export class NovaSenhaComponent implements OnInit {
	constructor(
		private formBuilder: UntypedFormBuilder,
		private redefinirSenha: RedefinirSenhaService,
		private router: Router
	) {}

	validaToken(param) {
		var tokenUrl = window.location.search.split("?");

		if (tokenUrl[1] != undefined) {
			tokenUrl[1] = tokenUrl[1].substring(0, tokenUrl[1].length - 1);
			var jsonToken = { token: tokenUrl[1], email: param.emailRedefine };
			if (param.nova_senha == param.confirma_nova_senha) {
				this.redefinirSenha.validaToken(jsonToken).subscribe(
					(token) => {
						this.redefinirSenha.novaSenha(param).subscribe(
							(senha) => {
								this.criarFormulario();
								swalSuccess("Senha redefinida com sucesso!");
								this.router.navigate(["/login"]);
							},
							(err) => {
								swalError("Erro ao redefinir senha!");
							}
						);
					},
					(err) => {
						swalError(err.error.data.message);
					}
				);
			} else {
				swalError("Senhas informadas são diferentes!");
			}
		} else {
			swalError(
				"Url inválida, clique no link que foi enviado para o seu e-mail!"
			);
		}
	}

	//Form
	redefineSenhaForm: UntypedFormGroup;

	//Auxiliares
	emailPattern =
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	criarFormulario() {
		this.redefineSenhaForm = this.formBuilder.group({
			emailRedefine: this.formBuilder.control("", [
				Validators.required,
				Validators.pattern(this.emailPattern),
			]),
			nova_senha: this.formBuilder.control("", [
				Validators.required,
				Validators.minLength(3),
			]),
			confirma_nova_senha: this.formBuilder.control("", [
				Validators.required,
				Validators.minLength(3),
			]),
		});
	}

	ngOnInit() {
		this.criarFormulario();
		$(".redefine").css(
			"background-image",
			"url(../../assets/img/login" +
				Math.floor(Math.random() * 6 + 1) +
				".jpg)"
		);
	}
}
