<div class="redefine">
  <div class="col-md-4 col-md-offset-4" style="top: 20%;">
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="text-center">
          <h2 class="text-center">Redefinição de Senha</h2>
          <div class="panel-body">
            <form [formGroup]="redefineSenhaForm">
              <mt-input-container errorMessage="E-mail inválido" label="Nome">
                <div class="form-group input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-envelope"></i>
                  </span>
                  <input id="emailRedefine" formControlName="emailRedefine" placeholder="Endereço de e-mail"
                    class="form-control" type="email">
                </div>
              </mt-input-container>
              <mt-input-container errorMessage="Campo Obrigátorio e com 5 caracteres" label="Nova Senha">
                <div class="form-group input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-lock"></i>
                  </span>
                  <input id="nova_senha" formControlName="nova_senha" placeholder="Nova Senha" class="form-control"
                    type="password">
                </div>
              </mt-input-container>
              <mt-input-container errorMessage="Campo Obrigátorio e com 5 caracteres" label="Confirmação Nova Senha">
                <div class="form-group input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-lock"></i>
                  </span>
                  <input id="confirma_nova_senha" formControlName="confirma_nova_senha"
                    placeholder="Confirmação Nova Senha" class="form-control" type="password">
                </div>
              </mt-input-container>
            </form>
            <button type="button" class="btn btn-lg btn-block" [disabled]="!redefineSenhaForm.valid"
              (click)="validaToken(redefineSenhaForm.value)">Redefinir Senha</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>