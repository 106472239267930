import { Component, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { swalError, swalSuccess } from "../../utils/swal-toast.utils";
import { RedefinirSenhaService } from "./redefine-senha.service";
declare var $: any;

@Component({
	selector: "app-redefinir-senha",
	templateUrl: "./redefinir-senha.component.html",
	styleUrls: ["./redefinir-senha.component.scss"],
})
export class RedefinirSenhaComponent implements OnInit {
	constructor(
		private formBuilder: UntypedFormBuilder,
		private redefinirSenha: RedefinirSenhaService
	) {}

	redefinir(param) {
		this.redefinirSenha.redefineSenha(param).subscribe(
			(senha) => {
				swalSuccess(
					"Enviamos um link de redefinição de senha para o seu e-mail!"
				);
				this.criarFormulario();
			},
			(err) => {
				swalError(err.error.erro);
			}
		);
	}

	//Form
	redefineForm: UntypedFormGroup;

	//Auxiliares
	emailPattern =
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	criarFormulario() {
		this.redefineForm = this.formBuilder.group({
			emailRedefine: this.formBuilder.control("", [
				Validators.required,
				Validators.pattern(this.emailPattern),
			]),
		});
	}

	ngOnInit() {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			if (
				(navigator.userAgent.indexOf("Opera") &&
					navigator.userAgent.indexOf("OPR")) != -1 &&
				navigator.userAgent.indexOf("Chrome") != -1 &&
				navigator.userAgent.indexOf("Safari") != -1 &&
				navigator.userAgent.indexOf("Firefox") != -1 &&
				navigator.userAgent.indexOf("MSIE") != -1
			) {
				$("#arrowBack").hide();
			}
		}
		this.criarFormulario();
		$(".redefine").css(
			"background-image",
			"url(../../assets/img/login" +
				Math.floor(Math.random() * 6 + 1) +
				".jpg)"
		);
	}
}
