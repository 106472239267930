import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import { httpOptions } from './../../http';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  saveUsuario(param): Observable<any[]> {
    return this.http
      .post(`${environment.api}/usuario`, JSON.stringify(param), httpOptions)
      .map(response => response['data']);
  }

  updateUsuario(param): Observable<any[]> {
    return this.http
      .put(`${environment.api}/usuario`, JSON.stringify(param), httpOptions)
      .map(response => response['data']);
  }

  deleteUsuario(param): Observable<any[]> {
    return this.http
      .delete(`${environment.api}/usuario-delete/` + param, httpOptions)
      .map(response => response['data']);
  }

  listUsuarios(): Observable<any[]> {
    return this.http
      .get(`${environment.api}/usuarios`, httpOptions)
      .map(response => response['data']);
  }

  listUsers(): Observable<any[]> {
    return this.http
      .get(`${environment.api}/users`, httpOptions)
      .map(response => response['data']);
  }

  listUsuariosByPonto(x): Observable<any[]> {
    return this.http
      .get(`${environment.api}/usuarios-by-ponto/` + x, httpOptions)
      .map(response => response['data']);
  }

  listUsuariosSeparator(param): Observable<any[]> {
    return this.http
      .get(`${environment.api}/usuarios-separator/` + param, httpOptions)
      .map(response => response['data']);
  }

  listUsuariosByGrupo(x): Observable<any[]> {
    return this.http
      .get(`${environment.api}/usuarios-by-grupo/` + x, httpOptions)
      .map(response => response['data']);
  }
}
