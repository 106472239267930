import { LoginService } from './login/login.service';
import {CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment} from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanLoad, CanActivate {

    constructor(
        private loginService: LoginService,
        private router: Router
    ) { }

    async checkAuthenticantion(path: string, token: string): Promise<boolean> {

        const loggedIn = this.loginService.isLoggedIn(token);

        if (!loggedIn) {
            this.loginService.logout();
            return loggedIn;
        }

        path = path.split('/')[0];

        if (!this.loginService.allPermissions) {
            this.loginService.allPermissions = await this.loginService.getAllPerms()
        }

        const perms = this.loginService.allPerms

        this.loginService.perms = perms[path]

        return !!(perms && perms[path])
    }

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        return this.checkAuthenticantion(route.path, null);
    }

    canActivate(activedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<boolean> {
       let token = null;
        if (activedRoute.queryParams.token != null && activedRoute.queryParams.token !== undefined) {
            window.localStorage.setItem('token', activedRoute.queryParams.token);

            token = activedRoute.queryParams.token;

            activedRoute.queryParams.idusuario != null && activedRoute.queryParams.idusuario != undefined ?  window.localStorage.setItem('id', activedRoute.queryParams.idusuario) : null;

            return this.checkAuthenticantion(activedRoute.routeConfig.path, token);
        } else {
            return this.checkAuthenticantion(activedRoute.routeConfig.path, token);
        }
    }

    rota(activedRoute: ActivatedRouteSnapshot, routerState?: RouterStateSnapshot): string {
        return activedRoute.routeConfig.path;
    }
}
