<main>
	<table
		datatable
		[dtOptions]="dtOptions"
		[dtTrigger]="dtTrigger"
		class="table table-condensed table-hover"
	>
		<thead>
			<tr>
				<th *ngIf="hasCheckbox" class="text-center"></th>

				<th
					*ngFor="let col of cols"
					class="text-center"
					[title]="col.titleHeader || ''"
				>
					{{ col.colName }}
				</th>

				<th *ngIf="actions" class="text-center">Ações</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let item of content; index as i"
				[title]="title && title.line ? title.line(item) : ''"
			>
				<td *ngIf="hasCheckbox">
					<input
						#check
						type="checkbox"
						[checked]="item.checked === true"
						(change)="emitCheckbox(item, 'checkbox', check.checked)"
					/>
				</td>

				<td
					*ngFor="let col of cols"
					[ngClass]="col.style ? col.style(item) : undefined"
					[title]="
						col.titleLine
							? col.titleLine(item)
							: title && title.line
							? title.line(item)
							: ''
					"
					(click)="col.click ? emitEvent(item, 'click') : null"
					(contextmenu)="
						col.contextmenu ? emitEvent(item, 'contextmenu') : null;
						(false)
					"
				>
					{{ col.colVal(item) }}
				</td>

				<td *ngIf="actions">
					<a
						*ngIf="
							loginService.getActionPerm('Alterar') &&
							actions.includes('Alterar')
						"
						title="Alterar Item"
						(click)="emitEvent(item, 'edit')"
						class="btnAlter"
					>
						<i class="far fa-edit" aria-hidden="true"></i>
					</a>

					<a
						*ngIf="
							loginService.getActionPerm('Duplicar') &&
							actions.includes('Duplicar')
						"
						title="Duplicar Item"
						(click)="emitEvent(item, 'duplicate')"
						class="btnClone"
					>
						<i class="fas fa-clone" aria-hidden="true"></i>
					</a>

					<a
						*ngIf="
							loginService.getActionPerm('Excluir') &&
							actions.includes('Excluir')
						"
						title="Excluir Item"
						(click)="emitEvent(item, 'delete')"
						class="btnDrop"
					>
						<i class="fas fa-trash" aria-hidden="true"></i>
					</a>

					<a
						*ngIf="
							loginService.getActionPerm('Alterar') &&
							actions.includes('Configurar')
						"
						title="Configurar Item"
						(click)="emitEvent(item, 'config')"
						class="btnConfig"
					>
						<i class="fa fa-cogs" aria-hidden="true"></i>
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</main>
