// Angular
import {
	HashLocationStrategy,
	LocationStrategy,
	registerLocaleData,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";

// Componentes
import { AppComponent } from "./app.component";
import { LoginComponent } from "./security/login/login.component";
import { NovaSenhaComponent } from "./security/nova-senha/nova-senha.component";
import { PrimeiroAcessoComponent } from "./security/primeiro-acesso/primeiro-acesso.component";
import { RedefinirSenhaComponent } from "./security/redefinir-senha/redefinir-senha.component";
import { HeaderComponent } from "./template/header/header.component";
import { NotFoundComponent } from "./template/not-found/not-found.component";
import { SidebarComponent } from "./template/sidebar/sidebar.component";

// Services
import { AuthInterceptor } from "./security/auth.interceptor";
import { LoggedInGuard } from "./security/loggein.guard";
import { LoginService } from "./security/login/login.service";

// Project
import { ROUTES } from "./app-routes";
import { AplicationErrorHandler } from "./security/app.error-handler";
import { SharedModule } from "./shared/shared.module";

// Loader
import { LoaderComponent } from "./loader/loader.component";
import { FooterComponent } from "./template/footer/footer.component";

// Locale
import localePt from "@angular/common/locales/pt";

registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		SidebarComponent,
		NotFoundComponent,
		LoginComponent,
		PrimeiroAcessoComponent,
		RedefinirSenhaComponent,
		NovaSenhaComponent,
		LoaderComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		SharedModule.forRoot(),
		RouterModule.forRoot(ROUTES, {
			preloadingStrategy: PreloadAllModules,
			scrollPositionRestoration: "top",
			relativeLinkResolution: "legacy",
		}),
		BrowserAnimationsModule,
	],
	providers: [
		LoginService,
		LoggedInGuard,
		{
			provide: ErrorHandler,
			useClass: AplicationErrorHandler,
			multi: false,
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
			multi: false,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: "pt-BR" },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
