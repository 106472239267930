import { Component, Input, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { swalError, swalInfo } from "../../utils/swal-toast.utils";

declare var $: any;

@Component({
	selector: "app-export-excel",
	templateUrl: "./export-excel.component.html",
	styleUrls: ["./export-excel.component.scss"],
})
export class ExportExcelComponent implements OnInit {
	constructor(private appService: AppService) {}

	modalColumnExport: boolean = false;

	@Input() dados: any[] = [];
	@Input() name_obj: string;

	colunas: any[] = [];
	exports: any[] = [];
	headers: any[] = [];

	pushExport(col, event) {
		if (event.srcElement.checked == true) {
			this.headers.push(col.show_name);
			this.exports.push(col.column_name);
		} else {
			var col_show = col.show_name;
			var col_name = col.column_name;
			this.headers.splice(this.headers.indexOf(col_show), 1);
			this.exports.splice(this.exports.indexOf(col_name), 1);
		}
	}

	hideModal() {
		$("#modalColumnExport").modal("hide");
		this.modalColumnExport = false;
	}

	csv() {
		var csv = "";
		for (var h = 0; h < this.headers.length; h++) {
			csv = csv + this.headers[h] + ";";
			if (h == this.headers.length - 1) {
				csv = csv + "\n ";
			}
		}

		if (this.dados.length > 0) {
			var exporta = this.exports;
			this.dados.forEach(function (linha) {
				var aux = "";
				for (var h = 0; h < exporta.length; h++) {
					if (h != 0) {
						aux += ";" + eval("linha." + exporta[h].toLowerCase());
					} else {
						aux += eval("linha." + exporta[h].toLowerCase());
					}
				}
				csv += aux + "\n";
			});
			var hiddenElement = document.createElement("a");
			hiddenElement.href = "data:text/csv;charset=utf-8," + csv;
			hiddenElement.target = "_blank";
			hiddenElement.download = this.name_obj + ".csv";
			hiddenElement.click();
		} else {
			swalInfo("Não existem dados para exportar!");
		}
	}

	xls() {
		var exporta = this.exports;
		if (this.dados.length > 0) {
			var array = [];
			this.dados.forEach(function (linha) {
				var dados = [];

				var objAuxiliar = $.extend(true, {}, linha);

				//Dicionário
				if (objAuxiliar.manejo) {
					if (objAuxiliar.manejo == true) {
						objAuxiliar.manejo = "Sim";
					} else {
						objAuxiliar.manejo = "Não";
					}
				} else {
					objAuxiliar.manejo = "Não";
				}

				if (objAuxiliar.tipo_motor == 1) {
					objAuxiliar.tipo_motor = "Captação";
				} else if (objAuxiliar.tipo_motor == 2) {
					objAuxiliar.tipo_motor = "Pivô";
				} else if (objAuxiliar.tipo_motor == 3) {
					objAuxiliar.tipo_motor = "Gotejo";
				} else if (objAuxiliar.tipo_motor == 4) {
					objAuxiliar.tipo_motor = "Asperção";
				} else if (objAuxiliar.tipo_motor == 5) {
					objAuxiliar.tipo_motor = "Silo";
				}

				if (objAuxiliar.mes == 1) {
					objAuxiliar.mes = "Janeiro";
				} else if (objAuxiliar.mes == 2) {
					objAuxiliar.mes = "Fevereiro";
				} else if (objAuxiliar.mes == 3) {
					objAuxiliar.mes = "Março";
				} else if (objAuxiliar.mes == 4) {
					objAuxiliar.mes = "Abril";
				} else if (objAuxiliar.mes == 5) {
					objAuxiliar.mes = "Maio";
				} else if (objAuxiliar.mes == 6) {
					objAuxiliar.mes = "Junho";
				} else if (objAuxiliar.mes == 7) {
					objAuxiliar.mes = "Julho";
				} else if (objAuxiliar.mes == 8) {
					objAuxiliar.mes = "Agosto";
				} else if (objAuxiliar.mes == 9) {
					objAuxiliar.mes = "Setembro";
				} else if (objAuxiliar.mes == 10) {
					objAuxiliar.mes = "Outubro";
				} else if (objAuxiliar.mes == 11) {
					objAuxiliar.mes = "Novembro";
				} else if (objAuxiliar.mes == 12) {
					objAuxiliar.mes = "Dezembro";
				}

				if (objAuxiliar.status) {
					if (objAuxiliar.status == 1) objAuxiliar.status = "Ativo";
					if (objAuxiliar.status == 2) objAuxiliar.status = "Inativo";
					if (objAuxiliar.status == 3)
						objAuxiliar.status = "Bloqueado";
				}

				for (var h = 0; h < exporta.length; h++) {
					dados.push(eval("objAuxiliar." + exporta[h].toLowerCase()));
				}
				array.push(dados);
			});
		}
		this.appService.generateExcel(
			"Lista de " + this.name_obj + "",
			this.headers,
			array
		);
	}

	exportar() {
		this.exports = [];
		this.headers = [];
		this.appService.listCollumns(this.name_obj).subscribe(
			(colunas) => {
				this.modalColumnExport = true;
				this.colunas = colunas;
				for (let i = 0; i < colunas.length; i++) {
					this.headers.push(colunas[i].show_name);
					this.exports.push(colunas[i].column_name);
				}
				setTimeout(() => {
					this.modalColumnExport = true;
					$("#modalColumnExport").modal("show");
				}, 200);
			},
			(err) => {
				swalError("Erro ao listar colunas");
			}
		);
		this.exports = [];
	}

	ngOnInit() {
		this.exportar();
		this.appService.showModalExportExcel.subscribe((modal) => {
			this.exportar();
		});
	}
}
