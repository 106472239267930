import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'

@Injectable({
    providedIn: 'root'
})
export class PainelService {
    constructor(private http: HttpClient) { }

    tabPainel = new EventEmitter<any>()

    tab(tab) {
        this.tabPainel.emit(tab);
    }
}