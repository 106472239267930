import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { swalError } from "../../utils/swal-toast.utils";
import { LoginService } from "./login.service";

declare var $: any;

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	@ViewChild("passEye") passEye: ElementRef;

	constructor(
		private loginService: LoginService,
		private router: Router,
		private formBuilder: UntypedFormBuilder
	) {}

	btnLogin: boolean = false;
	showPass: boolean = false;

	loginForm!: UntypedFormGroup;

	changePassView() {
		this.passEye.nativeElement.type === "text"
			? this.passEye.nativeElement.setAttribute("type", "password")
			: this.passEye.nativeElement.setAttribute("type", "text");

		this.showPass = !this.showPass;
	}

	login(params) {
		this.btnLogin = true;

		this.loginService.login(params).subscribe(
			(user) => {
				window.localStorage.setItem("nome", user.nome);
				window.localStorage.setItem("login", user.login);
				window.localStorage.setItem("id", user.id);
				window.localStorage.setItem("token", user.token);
				window.localStorage.setItem(
					"tokenReportApi",
					user.tokenReportApi
				);
				window.localStorage.setItem("tipo_usuario", user.tipoUsuario);
				window.localStorage.setItem("mensagem", user.mensagem);
				window.localStorage.setItem(
					"data_hora_login",
					user.dataHoraLogin
				);

				this.router.navigate(["/ponto-all"]);
			},
			(err) => {
				if (err.status === 0 || err.status === "0") {
					swalError("Erro ao comunicar com o servidor!");
				} else {
					this.btnLogin = false;
					swalError(err);
				}
			}
		);
	}

	ngOnInit() {
		this.createForm();

		if (this.loginService.isLoggedIn(null)) {
			this.router.navigate(["/ponto-all"]);
		} else {
			$(".back1").css(
				"background-image",
				"url(../../assets/img/login" +
					Math.floor(Math.random() * 6 + 1) +
					".webp)"
			);
			$(document).keypress((e) => {
				if (e.which === 13 || e.which === "13") {
					$("#entrar").click();
				}
			});
		}
	}

	createForm() {
		this.loginForm = this.formBuilder.group({
			usuario: this.formBuilder.control(null, [Validators.required]),
			senha: this.formBuilder.control(null, [Validators.required]),
		});
	}
}
