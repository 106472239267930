import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "../security/login/login.service";
import { swalInfo } from "../utils/swal-toast.utils";

@Injectable({
	providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private injector: Injector,
		private loginService: LoginService
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let token; // = (window.localStorage.getItem('token') == null || window.localStorage.getItem('token') == undefined ? window.location.hash: )
		if (
			request.urlWithParams.split("//")[1].split("/")[0] ===
			"viacep.com.br"
		) {
			return next.handle(request);
		} else {
			if (request.urlWithParams.indexOf("monitorar-report") !== -1) {
				token = window.localStorage.getItem("tokenReportApi");
			} else {
				token =
					window.localStorage.getItem("token") &&
					window.localStorage.getItem("id")
						? window.localStorage.getItem("token") +
						  "." +
						  window.localStorage.getItem("id")
						: undefined;
			}

			if (
				request.url.includes("token=") &&
				request.url.includes("idusuario=")
			) {
				const urlParams = request.url.match(/[^&?]*?=[^&?]*/g);

				const tokenParam = urlParams.findIndex((el) =>
					el.includes("token=")
				);
				const idusuarioParam = urlParams.findIndex((el) =>
					el.includes("idusuario=")
				);

				let token, idusuario;
				if (tokenParam !== -1 && idusuarioParam !== -1) {
					idusuario =
						urlParams[idusuarioParam].split("idusuario=")[1];
					token =
						urlParams[tokenParam].split("token=")[1] +
						`.${idusuario}`;
				}

				const authRequest = request.clone({
					setHeaders: {
						Authorization: "Bearer " + token,
					},
				});

				return next.handle(authRequest);
			}

			if (token == undefined && request.url.indexOf("login-web") == -1) {
				this.loginService.logout();
				return;
			}

			if (this.loginService.isTokenExpired()) {
				this.loginService.logout();

				setTimeout(() => {
					swalInfo("Sessão expirada, por favor relogar!");
				}, 50);
				return;
			}

			const authRequest = request.clone({
				setHeaders: {
					Authorization: "Bearer " + token,
				},
			});

			return next.handle(authRequest).pipe(
				catchError((err, caught) => {
					if (err.status == 401) {
						swalInfo(
							"Problema de autenticação, por favor relogar!"
						);
						setTimeout(() => {
							this.loginService.logout();
						}, 3000);
					}

					if ("erro" in err.error) {
						return throwError(err.error.erro);
					} else if ("err" in err.error) {
						return throwError(err.error.err);
					} else {
						return throwError(err.error.erro);
					}
				})
			);
		}
	}
}
