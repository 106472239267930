import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { httpOptions } from '../../http';

import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RedefinirSenhaService {

    constructor(private http: HttpClient) { }

    validaToken(param): Observable<any[]> {
        return this.http
            .post(`${environment.openApi}/valida-token`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

    novaSenha(param): Observable<any[]> {
        return this.http
            .post(`${environment.openApi}/nova-senha`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

    redefineSenha(param): Observable<any[]> {
        return this.http
            .post(`${environment.openApi}/redefine-senha`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }
}
