import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[appNumbersOnly]",
})
export class NumbersOnlyDirective {
	constructor(private el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event: any) {
		const initialValue = this.el.nativeElement.value.replace(/\D/g, "");
		this.el.nativeElement.value = initialValue;
	}
}
