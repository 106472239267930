export class Usuario {
    id: string;
    nome: string;
    email: string;
    login?: string;
    senha?: string;
    token?: string;
    primeiroAcesso?: number;
    tokenReportApi?: string;
    tipoUsuario?: string;
    mensagem: string;
    dataHoraLogin: string;
}
