<aside
	#mainSidebar
	class="main-sidebar sidebar-dark-primary elevation-4"
	style="background-color: #1e3a2d !important"
	xmlns="http://www.w3.org/1999/html"
	xmlns="http://www.w3.org/1999/html"
>
	<!-- Brand Logo -->
	<a [routerLink]="['/']" class="brand-link">
		<img
			src="../../assets/img/simbolo.png"
			alt="AdminLTE Logo"
			class="brand-image img-circle elevation-3"
			style="opacity: 0.8"
		/>

		<span class="brand-text font-weight-light">
			<b>MONITORAR </b>
			<!--            <p class="text-center" style="font-size: small">Gestão de Energia</p>-->
		</span>

		<span></span>

		<!--        <span class="brand-text font-weight-normal"><b>Gestão de Energia</b></span>-->
	</a>

	<!-- Sidebar -->
	<div class="sidebar">
		<!-- Sidebar Menu -->
		<nav class="mt-2">
			<ul
				class="nav nav-pills nav-sidebar flex-column"
				data-widget="treeview"
				role="menu"
				data-accordion="false"
			>
				<li class="nav-header">MENU PRINCIPAL</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					id="Clientes"
					*ngIf="loginService.getPermissionTela('cliente-all')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/cliente-all">
						<i class="nav-icon fa fa-users"></i>
						<span>Clientes</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('ponto-all')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/ponto-all">
						<i class="nav-icon fa fa-map-marker-alt"></i>
						<span>Pontos</span>
					</a>
				</li>

				<!-- <li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('energia-injetada')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/energia-injetada">
						<i class="nav-icon fa fa-recycle"></i>
						<span>Triangulação</span>
					</a>
				</li> -->

				<li
					class="nav-item"
					(click)="closeMenu()"
					routerLinkActive="active"
					*ngIf="loginService.getPermissionTela('alarme')"
				>
					<a class="nav-link" href routerLink="/alarme">
						<i class="nav-icon fa fa-bell"></i>
						<span>Alarmes</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('os')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/os">
						<i class="nav-icon fa fa-envelope"></i>
						<span>Atendimentos</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('documento')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/documento">
						<i class="nav-icon fa fa-file"></i>
						<span>Documentos</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('log')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/log">
						<i class="nav-icon fa fa-info"></i>
						<span>Log</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('mapa')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/mapa">
						<i class="nav-icon fa fa-map"></i>
						<span>Mapa</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="
						loginService.getPermissionTela(
							'parametrizacao-tarifaria'
						)
					"
					routerLinkActive="active"
				>
					<a
						class="nav-link"
						href
						routerLink="/parametrizacao-tarifaria"
					>
						<i class="nav-icon fa fa-bolt"></i>
						<span>Parametrização Tarif.</span>
					</a>
				</li>

				<li
					class="nav-item"
					(click)="closeMenu()"
					*ngIf="loginService.getPermissionTela('relatorio')"
					routerLinkActive="active"
				>
					<a class="nav-link" href routerLink="/relatorio">
						<i
							class="nav-icon fa fa-database"
							aria-hidden="true"
						></i>
						<span>Dados</span>
					</a>
				</li>

				<li *ngIf="getPermCadastro()" class="nav-item">
					<a href class="nav-link">
						<i class="nav-icon fa fa-plus-square"></i>
						<p>
							Cadastros
							<i class="fas fa-angle-left right"></i>
						</p>
					</a>
					<ul class="nav nav-treeview">
						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('categorias')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/categorias">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Categorias</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('postos')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/postos">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Postos</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('bandeira')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/bandeira">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Bandeira Tarifaria</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="
								loginService.getPermissionTela(
									'configura-alarme'
								)
							"
							routerLinkActive="active"
						>
							<a
								class="nav-link"
								href
								routerLink="/configura-alarme"
							>
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Config. Alarmes</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="
								loginService.getPermissionTela(
									'configuracao-layout-grafico'
								)
							"
							routerLinkActive="active"
						>
							<a
								class="nav-link"
								href
								routerLink="/configuracao-layout-grafico"
							>
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Config. Gráficos</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="
								loginService.getPermissionTela(
									'configura-tarifa'
								)
							"
							routerLinkActive="active"
						>
							<a
								class="nav-link"
								href
								routerLink="/configura-tarifa"
							>
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Config. Tarifas</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('diverso')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/diverso">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Diverso</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('energia')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/energia">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Energias</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="
								loginService.getPermissionTela(
									'incidencia-icms'
								)
							"
							routerLinkActive="active"
						>
							<a
								class="nav-link"
								href
								routerLink="/incidencia-icms"
							>
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Incidência de ICMS</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('permissao')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/permissao">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Permissões</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('usuario')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/usuario">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>Usuários</span>
							</a>
						</li>

						<li
							class="nav-item"
							(click)="closeMenu()"
							*ngIf="loginService.getPermissionTela('usuario')"
							routerLinkActive="active"
						>
							<a class="nav-link" href routerLink="/whatsapp">
								<i class="nav-icon fa fa-circle-o"></i>
								<span>WhatsApp</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
		<!-- /.sidebar-menu -->
	</div>
	<!-- /.sidebar -->
</aside>
