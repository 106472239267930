import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {httpOptions} from './../http';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  listCliente(id): Observable<any[]> {
    return this.http
      .get(`${environment.api}/cliente/` + id, httpOptions)
      .map(response => response['data']);
  }

  buscaLogoCliente(id): Observable<any[]> {
    return this.http
      .get(`${environment.api}/buscaLogoCliente/` + id, httpOptions)
      .map(response => response['data']);
  }

  listClientes(): Observable<any[]> {
    return this.http
      .get(`${environment.api}/clientes`, httpOptions)
      .map(response => response['data']);
  }

  deleteCliente(param): Observable<any[]> {
    return this.http
      .delete(`${environment.api}/cliente-delete/` + param, httpOptions)
      .map(response => response['data']);
  }

  saveCliente(param): Observable<any[]> {
    return this.http
      .post(`${environment.api}/cliente-create`, JSON.stringify(param), httpOptions)
      .map(response => response['data']);
  }

  filterCLientes(param): Observable<any[]> {
    return this.http
      .post(`${environment.api}/clientes-filter`, JSON.stringify(param), httpOptions)
      .map(response => response['data']);
  }

  verificaDuplicarCliente(param): Observable<any[]> {
    return this.http
      .post(`${environment.api}/verifica-duplicar-cliente`, JSON.stringify(param), httpOptions)
      .map(response => response['data']);
  }

}
