import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject } from 'rxjs';

import { LoginService } from 'src/app/security/login/login.service';

import { dt } from 'src/app/dt';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {

  // @ViewChild('lines') lines: ElementRef;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @Input() content;
  @Input() actions;
  @Input() rerenderTable!: Observable<boolean>
  @Input() cols;
  @Input() title;
  @Input() customDt;
  @Input() hasCheckbox;

  @Output() event: EventEmitter<{item: unknown, event: string}> =   new EventEmitter();
  @Output() checkbox: EventEmitter<{item: unknown, event: string, checked: boolean}> =   new EventEmitter();

  constructor(
    public loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.dtOptions = this.customDt || dt;

    this.rerenderTable.subscribe( (e: any) => this.rerender())
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  emitEvent(item, event) {
    this.event.emit({
      item,
      event
    })
  }

  emitCheckbox(item, event, checked) {
    this.checkbox.emit({
      item,
      event,
      checked
    })
  }

}
