export const dt = {
    responsive: true,
    scrollX: true,
    pagingType: 'full_numbers',
    pageLength: 100,
    bDestroy: true,
    destroy: true,
    language: {
        emptyTable: 'Sem dados disponíveis na tabela',
        info: 'Mostrando _END_ de _TOTAL_ registro(s)',
        infoEmpty: 'Mostrando 0 registros',
        infoFiltered: '(filtrados de _MAX_ registros)',
        infoPostFix: '',
        thousands: ',',
        lengthMenu: 'Mostrar _MENU_ registros',
        loadingRecords: 'Carregando...',
        processing: 'Processando...',
        search: 'Pesquisar:',
        zeroRecords: 'Nenhum registro encontrado',
        paginate: {
            first: '',
            last: '',
            next: 'Próximo',
            previous: 'Anterior'
        }
    }
};
