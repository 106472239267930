<div class="redefine">
  <div class="col-md-4 col-md-offset-4" style="top: 20%;">
    <div id="arrowBack" style="font-size: 18px; padding-top: 2%; position: absolute;" class="col-lg-12">
      <a href routerLink="/login" style="color: #000;">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </a>
    </div>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="text-center">
          <h3>
            <i class="fa fa-lock fa-4x"></i>
          </h3>
          <h2 class="text-center">Perdeu a senha?</h2>
          <p>Você pode obter uma nova senha aqui.</p>
          <div class="panel-body">
            <form [formGroup]="redefineForm">
              <mt-input-container errorMessage="E-mail inválido" label="Nome">
                <div class="form-group input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-envelope"></i>
                  </span>
                  <input id="emailRedefine" formControlName="emailRedefine" placeholder="Endereço de e-mail"
                    class="form-control" type="email">
                </div>
              </mt-input-container>
            </form>
            <button type="button" class="btn btn-lg btn-block" [disabled]="!redefineForm.valid"
              (click)="redefinir(redefineForm.value)">Redefinir Senha</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>