import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/security/login/login.service';
import { AppService } from 'src/app/app.service';
import { PermissaoService } from 'src/app/cadastro/permissao/permissao.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(
    private appService: AppService,
    private permissionService: PermissaoService,
    public loginService: LoginService,
  ) { }

  altura: any;
  perms: any = [];
  showCads: boolean = true;

  closeMenu() {
    // if (window.innerWidth <= 992) {
    //   this.appService.closedMenu(true);
    // }
  }

  getPermCadastro () {
    return (
      this.loginService.getPermissionTela('categorias') ||
      this.loginService.getPermissionTela('postos') ||
      this.loginService.getPermissionTela('bandeira') ||
      this.loginService.getPermissionTela('configura-alarme') ||
      this.loginService.getPermissionTela('configuracao-layout-grafico') ||
      this.loginService.getPermissionTela('configura-tarifa') ||
      this.loginService.getPermissionTela('diverso') ||
      this.loginService.getPermissionTela('energia') ||
      this.loginService.getPermissionTela('incidencia-icms') ||
      this.loginService.getPermissionTela('permissao') ||
      this.loginService.getPermissionTela('usuario')
    )
  }

  async checkAuthenticantion(): Promise<any> {

    const permissao = this.loginService.permissao()

    this.perms = await this.permissionService.userPerms(permissao).toPromise();

    setTimeout(() => {
      let itens = $("#cadastrosUl").children().length;
      if (itens == 0) {
        this.showCads = false;
        $("#cadastros").hide();
      } else {
        $("#cadastros").show();
      }
    }, 200);
  }

  ngOnInit() {
    this.checkAuthenticantion();
    this.appService.rerenderSidebar.subscribe(modal => {
      this.checkAuthenticantion();
    });
    $(document).ready(() => {
      /*const trees: any = $('[data-widget="tree"]');
      trees.tree();*/
    });
    this.altura = (window.innerHeight - 50) + 'px';
  }
}
