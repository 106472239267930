import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { LoginService } from "./security/login/login.service";
import { swalInfo } from "./utils/swal-toast.utils";
declare var $: any;

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	public sidebarMenuOpened = false;
	@ViewChild("contentWrapper") contentWrapper;

	isLoggedIn = false;
	showAdminBoard = false;
	showModeratorBoard = false;
	username?: string;

	constructor(
		private renderer: Renderer2,
		private loginService: LoginService
	) {}

	f11: boolean = false;
	autenticado(): boolean {
		if (this.loginService.isLoggedIn(null)) {
			return true;
		} else {
			return false;
		}
	}

	screen(): boolean {
		if (document.fullscreen) {
			return false;
		} else {
			return true;
		}
	}

	logout() {
		this.loginService.logout();
	}

	inatividade(x) {
		const aux = x;
		if (aux > 1) {
			$(".modal").modal("hide");
			swalInfo("Logout por inatividade");
			this.loginService.logout();
		}
	}

	toggleMenuSidebar() {
		if (this.sidebarMenuOpened) {
			this.renderer.removeClass(
				document.querySelector("body"),
				"sidebar-open"
			);
			this.renderer.addClass(
				document.querySelector("body"),
				"sidebar-collapse"
			);
			this.sidebarMenuOpened = false;
		} else {
			this.renderer.removeClass(
				document.querySelector("body"),
				"sidebar-collapse"
			);
			this.renderer.addClass(
				document.querySelector("body"),
				"sidebar-open"
			);
			this.sidebarMenuOpened = true;
		}
	}

	verificaUrl(): boolean {
		const valorUrl = location.href;
		const resultado = valorUrl.substr(valorUrl.indexOf("painel-app"), 10);

		if (resultado === "painel-app") {
			this.sidebarMenuOpened = false;
			return true;
		} else {
			return false;
		}
	}

	ngOnInit() {
		history.pushState(null, null, document.URL);
		window.addEventListener("popstate", () => {
			history.pushState(null, null, document.URL);
		});
		let idle = 0;

		setInterval(() => {
			if (
				location.href !== "http://localhost:4200/#/login" &&
				this.screen()
			) {
				idle++;
				this.inatividade(idle);
			}
		}, 1000 * 7200);

		$(document).mousemove((e) => {
			idle = 0;
		});

		$(document).keypress((e) => {
			idle = 0;
		});

		document.addEventListener(
			"fullscreenchange",
			(e) => {
				$("#logo_dash").toggle();
				$("#quiosque").toggle();
				$("#back_ponto_all").toggle();
				if (document.fullscreen) {
					$("#content").removeClass("content-wrapper");
					$("#content").addClass("content-wrapper-china");
				} else {
					$("#content").addClass("content-wrapper");
					$("#content").removeClass("content-wrapper-china");
				}
			},
			false
		);
	}
}
