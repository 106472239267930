import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import { environment } from "../../../environments/environment";
import { Usuario } from "../../cadastro/usuario/usuario";

import { JwtHelperService } from "@auth0/angular-jwt";
import { PermissaoService } from "src/app/cadastro/permissao/permissao.service";
import { actionPerms } from "../../models/auth.model.js";

const helper = new JwtHelperService();

@Injectable({
	providedIn: "root",
})
export class LoginService {
	usuario: Usuario;
	permissions!: any;
	allPermissions!: any;

	constructor(
		private http: HttpClient,
		private router: Router,
		private permissionService: PermissaoService
	) {}

	httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json",
		}),
	};

	async getAllPerms() {
		const permissao = this.permissao();

		return this.permissionService.userPerms(permissao).toPromise();
	}

	isLoggedIn(tokenApp): boolean {
		return tokenApp || window.localStorage.getItem("token");
	}

	handleLogin() {
		this.router.navigate(["/login"]);
	}

	logout() {
		window.localStorage.clear();
		this.usuario = undefined;
		this.perms = undefined;
		this.allPermissions = undefined;
		this.permissions = undefined;
		setTimeout(() => this.handleLogin(), 3000);
	}

	login(param): Observable<Usuario> {
		return this.http
			.post<Usuario>(
				`${environment.openApi}/login-web`,
				JSON.stringify(param),
				this.httpOptions
			)
			.do((usuario) => (this.usuario = usuario));
	}

	verifyToken() {
		const token = window.localStorage.getItem("token") || undefined;

		const decoded: Token = helper.decodeToken(token);

		return decoded;
	}

	private isTokenValid() {
		const token: Token = this.verifyToken();

		return !!(
			token &&
			!!(
				token.id !== undefined &&
				token.id !== null &&
				typeof token.id === "number"
			) &&
			!!(
				token.cliente_id !== undefined &&
				token.cliente_id !== null &&
				typeof token.cliente_id === "number"
			) &&
			!!(
				token.permissao !== undefined &&
				token.permissao !== null &&
				typeof token.permissao === "number"
			) &&
			!!(
				token.iss !== undefined &&
				token.iss !== null &&
				token.iss === "app-api"
			)
		);
	}

	permissao() {
		const token = window.localStorage.getItem("token") || undefined;

		const decoded: Token = helper.decodeToken(token);

		return decoded.permissao;
	}

	isTokenExpired() {
		const token = window.localStorage.getItem("token") || undefined;

		return token ? helper.isTokenExpired(token) : false;
	}

	getActionPerm(action: actionPerms) {
		return this.permissions.includes(action);
	}

	getPermissionTela(tela) {
		return !!(tela in this.allPerms);
	}

	get isLogged() {
		return this.isTokenValid();
	}

	get user() {
		return this.verifyToken();
	}

	get perms() {
		return this.permissions;
	}

	set perms(route_perms) {
		this.permissions = route_perms;
	}

	get allPerms() {
		return this.allPermissions;
	}
}

export interface Token {
	permissao: number;
	id: number;
	cliente_id: number;
	iss: string;
}
