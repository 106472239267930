import { Component, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { swalError, swalInfo, swalSuccess } from "../../utils/swal-toast.utils";
import { LoginService } from "../login/login.service";
import { UsuarioService } from "./../../cadastro/usuario/usuario.service";

declare var $: any;

@Component({
	selector: "app-primeiro-acesso",
	templateUrl: "./primeiro-acesso.component.html",
})
export class PrimeiroAcessoComponent implements OnInit {
	//Form
	redefineForm: UntypedFormGroup;

	//Objeto
	usuario: any = {};

	constructor(
		private usuarioService: UsuarioService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private loginService: LoginService
	) {}

	redefineSenha(usuario) {
		if (usuario.senha != usuario.confirmPassword) {
			swalError("Senhas diferentes!");
		} else {
			this.usuarioService.saveUsuario(usuario).subscribe(
				(usuario) => {
					swalSuccess("Senha redefinida com sucesso!");
					$("#modalSenha").modal("hide");
					this.loginService.usuario.primeiroAcesso = 2;
					this.router.navigate([""]);
				},
				(err) => {
					if (err.error.data == undefined) {
						swalError("Erro ao redefinir senha!");
					} else {
						swalError(err.error.data);
					}
				}
			);
		}
	}

	criarFormularioContato() {
		this.redefineForm = this.formBuilder.group({
			id: this.formBuilder.control(window.localStorage.getItem("id")),
			primeiro_acesso: this.formBuilder.control(1),
			senha: this.formBuilder.control("", [
				Validators.required,
				Validators.minLength(5),
			]),
			confirmPassword: this.formBuilder.control("", [
				Validators.required,
				Validators.minLength(5),
			]),
		});

		setTimeout(function () {
			$(".celular").mask("(99) 9 9999-9999");
		}, 500);
	}

	ngOnInit() {
		if (this.loginService.usuario != undefined) {
			if (this.loginService.usuario.primeiroAcesso == 1) {
				$("#modalSenha").modal("show");
				this.criarFormularioContato();
			} else {
				swalInfo("Sem permissão!");
			}
		}
	}
}
