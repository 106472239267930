import { Cep } from './cep.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../environments/environment';
import { httpOptions } from './http';

import * as fs from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    cep: any = {};
    constructor(private http: HttpClient) { }

    showModalExportExcel = new EventEmitter<any>();
    closeMenu = new EventEmitter<any>();
    rerenderSidebar = new EventEmitter<any>();
    showModalExportExcelCliente = new EventEmitter<any>();

    showModalExcel(x) {
        this.showModalExportExcel.emit(x);
    }

    showModalExcelCliente(x) {
        this.showModalExportExcelCliente.emit(x);
    }

    closedMenu(x) {
        this.closeMenu.emit(x);
    }

    rerenderMenu(x) {
        this.rerenderSidebar.emit(x);
    }

    coordenadasPontos() {
        return this.http
            .get(`${environment.api}/coordenadas-pontos/`, httpOptions)
            .map(response => response['data']);
    }

    findBandeira(): Observable<any[]> {
        return this.http
            .get(`${environment.api}/find-bandeira/`, httpOptions)
            .map(response => response['data']);
    }

    removeUploadImage(path): Observable<any> {
        var aux = { path: path };
        return this.http.post(`${environment.api}/remove-upload`, aux);
    }

    uploadImage(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.api}/upload`, formData);
    }

    saveArquivo(param): Observable<any[]> {
        return this.http
            .post(`${environment.api}/arquivo-create`, JSON.stringify(param), httpOptions)
            .map(response => response['data']);
    }

    deleteArquivo(param): Observable<any[]> {
        return this.http
            .delete(`${environment.api}/arquivo-delete/` + param, httpOptions)
            .map(response => response['data']);
    }

    buscar(cep: string) {
        var str = cep;
        cep = str.replace(/[^\d]+/g, '')
        return this.http.get(`https://viacep.com.br/ws/` + cep + `/json/`)
            .toPromise()
            .then(response => {
                return this.converterRespostaParaCep(response)
            });
    }

    findEstadoByUf(uf): Observable<any[]> {
        return this.http
            .get(`${environment.api}/estado-by-uf/` + uf, httpOptions)
            .map(response => response['data']);
    }

    listCollumns(table): Observable<any[]> {
        return this.http
            .get(`${environment.api}/columns/` + table, httpOptions)
            .map(response => response['data']);
    }

    findCidadeByName(cidade): Observable<any[]> {
        return this.http
            .get(`${environment.api}/cidade-by-name/` + cidade, httpOptions)
            .map(response => response['data']);
    }

    listEstados(): Observable<any[]> {
        return this.http
            .get(`${environment.api}/estados`, httpOptions)
            .map(response => response['data']);
    }

    listCidades(estado_id): Observable<any[]> {
        return this.http
            .get(`${environment.api}/cidades/` + estado_id, httpOptions)
            .map(response => response['data']);
    }

    generateExcel(title, header, data) {
        const Excel = require('exceljs');
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Monitorar");
        // Title row
        const titleRow = worksheet.addRow([title]);
        // Customize Title row
        titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
        worksheet.addRow([]);

        //Add Header Row
        let headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: 'FF0000FF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        // worksheet.addRows(data);
        // Add Data and Conditional Formatting
        data.forEach(d => {
            let row = worksheet.addRow(d);
        });
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 20;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 25;
        worksheet.getColumn(6).width = 28;
        worksheet.getColumn(7).width = 18;
        worksheet.getColumn(8).width = 18;
        worksheet.getColumn(9).width = 20;
        worksheet.getColumn(10).width = 22;
        worksheet.getColumn(11).width = 22;
        worksheet.getColumn(12).width = 16;
        worksheet.getColumn(13).width = 16;
        worksheet.addRow([])

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, ' ' + title + '.xlsx');
        })
    }

    private converterRespostaParaCep(cepNaResposta): Cep {
        let cep = new Cep();
        cep.cep = cepNaResposta.cep;
        cep.logradouro = cepNaResposta.logradouro;
        cep.complemento = cepNaResposta.complemento;
        cep.bairro = cepNaResposta.bairro;
        cep.cidade = cepNaResposta.localidade;
        cep.estado = cepNaResposta.uf;
        return cep;
    }
}
