<div class="wrapper" *ngIf="verificaUrl() != true">
	<app-header
		(toggleMenuSidebar)="toggleMenuSidebar()"
		*ngIf="screen() && autenticado()"
		id="header"
	></app-header>
	<app-sidebar *ngIf="screen() && autenticado()" id="sidebar"></app-sidebar>

	<div
		#contentWrapper
		[ngClass]="{ 'content-wrapper': autenticado() }"
		*ngIf="verificaUrl() != true"
	>
		<app-loader></app-loader>

		<router-outlet></router-outlet>
	</div>

	<app-footer></app-footer>

	<aside class="control-sidebar control-sidebar-dark"></aside>

	<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
</div>

<div class="wrapper" *ngIf="verificaUrl() == true">
	<app-loader></app-loader>

	<router-outlet></router-outlet>
</div>
