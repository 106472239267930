<div
	class="modal fade"
	data-bs-keyboard="false"
	style="padding-top: 5%"
	data-bs-backdrop="static"
	id="modalSenha"
	role="dialog"
>
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title">Redefinição de Senha</h3>
			</div>
			<div class="modal-body">
				<b style="text-align: center; font-size: 18px; color: red"
					>Como este é seu primeiro acesso, é necessário redefinir a
					senha!
				</b>
				<div class="row" style="margin-top: 20px">
					<form [formGroup]="redefineForm">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label
									>Nova Senha<span class="red">
										*
									</span></label
								>
								<mt-input-container
									errorMessage="Campo obrigatório e com 5 caracteres"
									label="Senha"
								>
									<input
										class="form-control"
										formControlName="senha"
										name="senha"
										type="password"
										placeholder="Nova Senha"
									/>
								</mt-input-container>
							</div>
						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="form-group">
								<label
									>Confirmar Senha<span class="red">
										*
									</span></label
								>
								<mt-input-container
									errorMessage="Campo obrigatório e com 5 caracteres"
									label="Confirmar Nova Senha"
								>
									<input
										class="form-control"
										formControlName="confirmPassword"
										name="confirmPassword"
										type="password"
										placeholder="Confirmar Nova Senha"
									/>
								</mt-input-container>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer">
				<button
					type="button"
					[disabled]="!redefineForm.valid"
					(click)="redefineSenha(redefineForm.value)"
					class="btn btn-success"
				>
					Salvar
				</button>
			</div>
		</div>
	</div>
</div>
