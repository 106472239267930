import swal from "sweetalert2";

export const swalSuccess = (text: String) => {
	swal.fire({
		text: `${text}`,
		timer: 3500,
		showConfirmButton: false,
		position: "bottom",
		toast: true,
		animation: false,
		customClass: {
			popup: "swal2-success",
			content: "swal2-success",
		},
	});
};

export const swalInfo = (text: String) => {
	swal.fire({
		text: `${text}`,
		timer: 3500,
		showConfirmButton: false,
		position: "bottom",
		toast: true,
		animation: false,
		customClass: {
			popup: "swal2-info",
			content: "swal2-info",
		},
	});
};

export const swalError = (text: String) => {
	swal.fire({
		text: `${text}`,
		timer: 3500,
		showConfirmButton: false,
		position: "bottom",
		toast: true,
		animation: false,
		customClass: {
			popup: "swal2-danger",
			content: "swal2-danger",
		},
	});
};
