import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { MacDirective } from "./directives/mac.directive";
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { UpperCaseDirective } from "./directives/upper-case.directive";
import { ExportExcelComponent } from "./export-excel/export-excel.component";
import { GenericTableComponent } from "./generic-table/generic-table.component";
import { InputComponent } from "./input/input.component";
import { CalculaDemandaPipe } from "./pipes/calcula-demanda.pipe";
import { horaToDiaPipe } from "./pipes/hora-to-dia.pipe";
import { pontosInfoPipe } from "./pipes/pontos-info";
import { solarPipe } from "./pipes/solar.pipe";

@NgModule({
	declarations: [
		InputComponent,
		ExportExcelComponent,
		CalculaDemandaPipe,
		horaToDiaPipe,
		solarPipe,
		pontosInfoPipe,
		GenericTableComponent,
		UpperCaseDirective,
		NumbersOnlyDirective,
		MacDirective,
	],
	imports: [
		DataTablesModule.forRoot(),
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		InputComponent,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ExportExcelComponent,
		CalculaDemandaPipe,
		horaToDiaPipe,
		solarPipe,
		pontosInfoPipe,
		GenericTableComponent,
		UpperCaseDirective,
		NumbersOnlyDirective,
		MacDirective,
	],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [],
		};
	}
}
