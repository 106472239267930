import { Routes } from "@angular/router";
import { LoggedInGuard } from "./security/loggein.guard";
import { LoginComponent } from "./security/login/login.component";
import { NovaSenhaComponent } from "./security/nova-senha/nova-senha.component";
import { PrimeiroAcessoComponent } from "./security/primeiro-acesso/primeiro-acesso.component";
import { RedefinirSenhaComponent } from "./security/redefinir-senha/redefinir-senha.component";
import { NotFoundComponent } from "./template/not-found/not-found.component";

export const ROUTES: Routes = [
	{
		path: "",
		redirectTo: "ponto-all",
		pathMatch: "full",
		canLoad: [LoggedInGuard],
	},
	{ path: "login", component: LoginComponent },
	{ path: "redefinir-senha", component: RedefinirSenhaComponent },
	{ path: "nova-senha", component: NovaSenhaComponent },
	{
		path: "primeiro-acesso",
		component: PrimeiroAcessoComponent,
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "dashboard",
		loadChildren: () =>
			import("./dashboard/dashboard.module").then(
				(m) => m.DashboardModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "cliente-all",
		loadChildren: () =>
			import("./cliente-all/cliente-all.module").then(
				(m) => m.ClienteAllModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "cliente/:id",
		loadChildren: () =>
			import("./cliente/cliente.module").then((m) => m.ClienteModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "alarme",
		loadChildren: () =>
			import("./alarme/alarme.module").then((m) => m.AlarmeModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "ponto-all",
		loadChildren: () =>
			import("./ponto-all/ponto-all.module").then(
				(m) => m.PontoAllModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "energia-injetada",
		loadChildren: () =>
			import("./energia-injetada/energia-injetada.module").then(
				(m) => m.EnergiaInjetadaModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "relatorio",
		loadChildren: () =>
			import("./relatorio/relatorio.module").then(
				(m) => m.RelatorioModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "os",
		loadChildren: () => import("./os/os.module").then((m) => m.OsModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "documento",
		loadChildren: () =>
			import("./documento/documento.module").then(
				(m) => m.DocumentoModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "log",
		loadChildren: () => import("./log/log.module").then((m) => m.LogModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "painel/:id",
		loadChildren: () =>
			import("./painel/painel.module").then((m) => m.PainelModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "painel-inj/:id",
		loadChildren: () =>
			import("./painel-inj/painel-inj.module").then(
				(m) => m.PainelInjModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "painel-app/:id",
		loadChildren: () =>
			import("./painelSemAuth/painel.module").then((m) => m.PainelModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: "categorias",
		loadChildren: () =>
			import("./cadastro/categorias/categorias.module").then(
				(m) => m.CategoriasModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "postos",
		loadChildren: () =>
			import("./cadastro/postos/postos.module").then(
				(m) => m.PostosModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "diverso",
		loadChildren: () =>
			import("./cadastro/diverso/diverso.module").then(
				(m) => m.DiversoModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "usuario",
		loadChildren: () =>
			import("./cadastro/usuario/usuario.module").then(
				(m) => m.UsuarioModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "whatsapp",
		loadChildren: () =>
			import("./cadastro/whatsapp/whatsapp.module").then(
				(m) => m.WhatsappModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "tarifa",
		loadChildren: () =>
			import("./cadastro/tarifa/tarifa.module").then(
				(m) => m.TarifaModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "energia",
		loadChildren: () =>
			import("./cadastro/energia/energia.module").then(
				(m) => m.EnergiaModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "incidencia-icms",
		loadChildren: () =>
			import("./cadastro/incidencia-icms/incidencia-icms.module").then(
				(m) => m.IncidenciaIcmsModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "permissao",
		loadChildren: () =>
			import("./cadastro/permissao/permissao.module").then(
				(m) => m.PermissaoModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "bandeira",
		loadChildren: () =>
			import("./cadastro/bandeira/bandeira.module").then(
				(m) => m.BandeiraModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "parametrizacao-tarifaria",
		loadChildren: () =>
			import(
				"./parametrizacao-tarifaria/parametrizacao-tarifaria.module"
			).then((m) => m.ParametrizacaoTarifariaModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "configura-imposto",
		loadChildren: () =>
			import(
				"./cadastro/configura-imposto/configura-imposto.module"
			).then((m) => m.ConfiguraImpostoModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "configuracao-layout-grafico",
		loadChildren: () =>
			import(
				"./cadastro/configuracao-layout-grafico/configuracao-layout-grafico.module"
			).then((m) => m.ConfiguracaoLayoutGraficoModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "mapa",
		loadChildren: () =>
			import("./mapa/mapa.module").then((m) => m.MapaModule),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{
		path: "configuracao",
		loadChildren: () =>
			import("./configuracao/configuracao.module").then(
				(m) => m.ConfiguracaoModule
			),
		canLoad: [LoggedInGuard],
		canActivate: [LoggedInGuard],
	},
	{ path: "**", component: NotFoundComponent },
];
