import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { httpOptions } from "./../http";

@Injectable({
	providedIn: "root",
})
export class PontoAllService {
	constructor(private http: HttpClient) {}

	listAppConfig(id): Observable<any[]> {
		return this.http
			.get(`${environment.api}/app-config/` + id, httpOptions)
			.map((response) => response["data"]);
	}

	listPontos(status, id): Observable<any[]> {
		return this.http
			.get(
				`${environment.api}/pontos-all/` + status + "/" + id,
				httpOptions
			)
			.map((response) => response["data"]);
	}

	getExcel(id: number, status: number): Observable<Blob> {
		return this.http.get(
			`${environment.api}/pontos/${id}/${status}/excel`,
			{
				responseType: "blob",
			}
		);
	}

	saveDemanda(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/demanda-create`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	listPostoTarifariosPonto(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/pontos-all/postos-tarifarios/` + param)
			.map((response) => response["data"]);
	}

	listDemandaPonto(id): Observable<any[]> {
		return this.http
			.get(`${environment.api}/demanda-ponto/` + id, httpOptions)
			.map((response) => response["data"]);
	}

	addDemandaPonto(param, id): Observable<any> {
		return this.http
			.post(
				`${environment.api}/demanda-ponto/` + id,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	updateDemandaPonto(param, id): Observable<any[]> {
		return this.http
			.put(
				`${environment.api}/demanda-ponto/` + id,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	deleteDemandaPonto(idDemanda, idPonto): Observable<any[]> {
		return this.http
			.delete(
				`${environment.api}/demanda-ponto/${idDemanda}&${idPonto}`,
				httpOptions
			)
			.map((response) => response["data"]);
	}

	listDemandaPontoPorTipo(idPonto, cronometrada): Observable<any[]> {
		return this.http
			.get(
				`${environment.api}/demanda-ponto/busca-por-tipo/` +
					idPonto +
					`/` +
					cronometrada
			)
			.map((response) => response["data"]);
	}

	listMetaPonto(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/meta-ponto/` + param)
			.map((response) => response["data"]);
	}

	listParcelaPonto(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/parcelas/` + param)
			.map((response) => response["data"]);
	}

	deleteEconomia(param): Observable<any[]> {
		return this.http
			.delete(`${environment.api}/economia-delete/` + param, httpOptions)
			.map((response) => response["data"]);
	}

	deleteParcela(param): Observable<any[]> {
		return this.http
			.delete(`${environment.api}/parcela-delete/` + param, httpOptions)
			.map((response) => response["data"]);
	}

	listCreditoPonto(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/creditos/` + param)
			.map((response) => response["data"]);
	}

	saveParcelaPonto(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/parcela-create`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	saveCreditoPonto(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/credito-create`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	listEconomiasPonto(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/economias/` + param)
			.map((response) => response["data"]);
	}

	listDemandaComplementar(param): Observable<any[]> {
		return this.http
			.get(`${environment.api}/demandas-complementares/` + param)
			.map((response) => response["data"]);
	}

	saveEconomia(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/economia-create`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	saveMeta(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/meta-create`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	fatorConversao(param): Observable<any[]> {
		return this.http
			.post(
				`${environment.api}/fator-conversao`,
				JSON.stringify(param),
				httpOptions
			)
			.map((response) => response["data"]);
	}

	listTarifasComImpostoEmbutido(param): Observable<any[]> {
		return this.http
			.get(
				`${environment.api}/pontos-all/info-tarifas/` + param,
				httpOptions
			)
			.map((response) => response["data"]);
	}
}
