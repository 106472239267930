<!--<header class="main-header">-->
<!--    <a href routerLink="/dashboard" class="logo">-->
<!--        <span class="logo-mini">-->
<!--            <img src="../../assets/img/simbolo.png" width="140px;">-->
<!--        </span>-->
<!--        <span class="logo-lg">-->
<!--            <img id="logo-open" src="../../assets/img/logo_verde.png" width="168px;">-->
<!--        </span>-->
<!--    </a>-->
<!--    <nav style="display: block;" class="navbar navbar-static-top">-->
<!--        <a href="#" id="toggleMenu" class="sidebar-toggle" data-toggle="push-menu" role="button">-->
<!--            <span class="sr-only">Toggle navigation</span>-->
<!--        </a>-->
<!--        <div style="padding-top: 8px; margin-bottom: -4px; float: left;" *ngIf="escondeHeaderPonto" class="col-lg-3 combografico">-->
<!--            <div class="form-group">-->
<!--                <select (ngModelChange)="detalhes($event)" [(ngModel)]="pontoSelect" class="form-control">-->
<!--                    <option value="{{ponto.id_ponto}}" *ngFor="let ponto of pontos">-->
<!--                        {{ponto.descricao}} - {{ponto.concessionaria_descricao}} </option>-->
<!--                </select>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="navbar-custom-menu">-->
<!--            <ul class="nav navbar-nav">-->
<!--                <li *ngIf="getPermissionItem('Configuração') == true" class="dropdown messages-menu open">-->
<!--                    <a href routerLink="/configuracao">-->
<!--                        <i class="fa fa-cog"></i>-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li *ngIf="getPermissionItem('Notificações de Atendimento') == true" class="dropdown messages-menu open">-->
<!--                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">-->
<!--                        <i class="fa fa-envelope-o"></i>-->
<!--                        <span *ngIf="ats.length > 0" class="label labelInfo">{{ats.length}}</span>-->
<!--                        <span *ngIf="ats.length == 0" class="label label-info">{{ats.length}}</span>-->
<!--                    </a>-->
<!--                    <ul class="dropdown-menu">-->
<!--                        <li class="header">Você tem {{ats.length}} atendimento(s)</li>-->
<!--                        <li>-->
<!--                            <ul class="menu">-->
<!--                                <li style="cursor: pointer !important;" style="display: flex;" *ngFor="let at of ats; index as i;">-->
<!--                                    <a class="cursor" style="width: 90%;" [routerLink]="['/os']">-->
<!--                                        <div class="pull-left" style="font-size: 16px; padding: 6px;">-->
<!--                                            <img height="28px" src="../../../assets/img/simbolo.png">-->
<!--                                        </div>-->
<!--                                        <h4 style="text-overflow: ellipsis; overflow: hidden; padding-right: 50px;">-->
<!--                                            {{at.mensagem}}-->
<!--                                            <small><i class="fa fa-clock-o"></i> {{at.sla}}</small>-->
<!--                                        </h4>-->
<!--                                        <p>{{at.assunto}}</p>-->
<!--                                    </a>-->
<!--                                    <div (click)="deleteAlerta(at)" style="padding-top: 14px; cursor: pointer; padding-left: 6px; color: red; font-size: 14px;" class="pull-right">-->
<!--                                        <span><i aria-hidden="true" class="fa fa-trash"></i></span>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li *ngIf="getPermissionItem('Notificações de Alarmes') == true" id="showAlerts" class="dropdown messages-menu open">-->
<!--                    <a href="#" class="dropdown-toggle " data-toggle="dropdown" aria-expanded="true">-->
<!--                        <i class="fa fa-bell-o"></i>-->
<!--                        <span *ngIf="alarmes.length > 0" class="label labelInfo">{{alarmes.length}}</span>-->
<!--                        <span *ngIf="alarmes.length == 0" class="label label-danger labelInfo">{{alarmes.length}}</span>-->
<!--                    </a>-->
<!--                    <ul class=" dropdown-menu">-->
<!--                        <li class="header">Você tem {{alarmes.length}} alarme(s)</li>-->
<!--                        <li>-->
<!--                            <ul class="menu">-->
<!--                                <li style="cursor: pointer !important;" style="display: flex;" *ngFor="let alarme of alarmes; index as i;">-->
<!--                                    <a class="cursor" style="width: 90%;" (click)="navi(alarme)">-->
<!--                                        <div class="pull-left" style="font-size: 16px;">-->
<!--                                            <i *ngIf="alarme.categoria == 2" style="color: red;" class="fa fa-bell-o"></i>-->
<!--                                            <i *ngIf="alarme.categoria == 1" style="color: red;" class="fa fa-usd"></i>-->
<!--                                        </div>-->

<!--                                        <h4 style="text-overflow: ellipsis; overflow: hidden; padding-right: 50px;">-->
<!--                                            {{alarme.mensagem}}-->

<!--                                            <small><i class="fa fa-clock-o"></i> {{alarme.sla}} </small>-->
<!--                                        </h4>-->
<!--                                        <p style="text-overflow: ellipsis;overflow: hidden;">{{alarme.descricao_ponto}}-->
<!--                                        </p>-->
<!--                                    </a>-->
<!--                                    <div (click)="deleteAlerta(alarme)" style="padding-top: 6px; cursor: pointer; padding-left: 6px; color: red; font-size: 14px;" class="pull-right">-->
<!--                                        <span><i aria-hidden="true" class="fa fa-trash"></i></span>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
<!--                        <li *ngIf="ats.length > 0" class="footer">-->
<!--                            <a [routerLink]="['/alarme']"> Visualizar alarmes</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->

<!--                <li class="dropdown user user-menu">-->
<!--                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">-->
<!--                        <i style="font-size: 25px; margin-right: 8px;" class="user-image fa fa-user-circle-o" aria-hidden="true"></i>-->
<!--                        <span class="hidden-xs">{{user.nome}}</span>-->
<!--                    </a>-->
<!--                    <ul class="dropdown-menu">-->
<!--                        <li class="user-header">-->
<!--                            <p style="color: #fff !important;">-->
<!--                                {{ user.nome }}-->
<!--                                <small>{{ user.email }}</small>-->
<!--                                <span style="color: #fff !important;">-->
<!--                                    {{bandeira.mensagem}}-->
<!--                                </span>-->
<!--                            </p>-->
<!--                        </li>-->
<!--                        <li class="user-footer">-->
<!--                            <div class="pull-right">-->
<!--                                <a (click)="logout()" style="background-color: #00a65a; color: #fff;" class="btn btn-default btn-flat">Sair</a>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </nav>-->
<!--</header>-->

<nav class="main-header navbar navbar-expand navbar-white navbar-light">
	<!--    <ul class="navbar-nav">-->
	<!--        <li class="nav-item">-->
	<!--            <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button">-->
	<!--                <i class="fas fa-bars"></i>-->
	<!--            </a>-->
	<!--        </li>-->
	<!--    </ul>-->
	<ul class="navbar-nav">
		<li class="nav-item">
			<a
				class="nav-link"
				(click)="toggleMenuSidebar.emit()"
				role="button"
				data-widget="pushmenu"
				data-auto-collapse-size="992"
			>
				<i class="fas fa-bars"></i>
			</a>
		</li>
		<li class="nav-item">
			<div class="nav-link text-black">
				{{ curTime$ | async | date : "EEEE, dd/MM/yyyy, HH:mm" : "pt" }}
			</div>
		</li>
	</ul>

	<ul class="navbar-nav ml-auto">
		<li class="nav-item dropdown user-menu">
			<a
				class="nav-link dropdown-toggle"
				data-toggle="dropdown"
				(click)="toggleDropdownMenu()"
			>
				{{ user.nome }}
			</a>

			<ul #dropdownMenu class="dropdown-menu dropdown-menu-right">
				<li class="dropdown-header">
					{{ user.nome }}
					<!-- <p style="color: #fff !important">
						{{ user.nome }}
						<small>{{ user.email }}</small>
						<span style="color: #fff !important">
							{{ bandeira.mensagem }}
						</span>
					</p> -->
				</li>
				<li class="user-footer">
					<div class="pull-right">
						<a
							(click)="logout()"
							style="background-color: #00a65a; color: #fff"
							class="btn btn-default btn-flat"
							>Sair</a
						>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</nav>
