import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'solar'
})
export class solarPipe implements PipeTransform {

  transform(hora : Date, voltagem : number)  {
    const sunrise = moment(hora).set('hours', 8).set('minute', 45);
    const sunset = moment(hora).set('hours', 17).set('minute', 30);

    const isDayLight = moment(hora).isBetween(sunrise, sunset);

    const medicaoSolar = isDayLight && voltagem >= 140 ? 'good' 
                        : isDayLight && (voltagem < 140 && voltagem > 70) ? 'warn' 
                          : !isDayLight ? 'night' 
                            : 'danger';

    return medicaoSolar;
  }

}
