import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { interval, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { PontoService } from "src/app/cliente/ponto/ponto.service";
import { PainelService } from "src/app/painel/painel.service";
import { PontoAllService } from "src/app/ponto-all/ponto-all.service";
import { Usuario } from "../../cadastro/usuario/usuario";
import { ClienteService } from "../../cliente/cliente.service";
import { LoginService } from "../../security/login/login.service";
import { swalError, swalInfo } from "../../utils/swal-toast.utils";

declare var $: any;

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	@Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild("dropdownMenu") dropdownMenu;
	@HostListener("document:click", ["$event"])
	clickout(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.hideDropdownMenu();
		}
	}

	curTime$: Observable<Date>;

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private loginService: LoginService,
		private clienteService: ClienteService,
		private pontoAllService: PontoAllService,
		private pontoService: PontoService,
		private painelService: PainelService,
		private router: Router,
		private appService: AppService
	) {
		this.curTime$ = interval(1000).pipe(map(() => new Date()));
	}

	// Objeto
	user: Usuario = new Usuario();
	bandeira: any = {};
	ponto: any = {};
	pontoSelect: any;
	escondeHeaderPonto: boolean = false;

	// Auxiliares
	private options = {
		weekday: "long",
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	} as const;

	date: string = new Date().toLocaleString("pt-BR", this.options);
	qtdAlertas: any;

	// Arrays
	clientes: any = [];
	ats: any = [];
	alarmes: any = [];
	perms: any = [];
	pontos: any = [];
	usuario: any = {};

	logout() {
		this.loginService.logout();
		window.location.reload();
	}

	toggleDropdownMenu() {
		if (this.dropdownMenu.nativeElement.classList.contains("show")) {
			this.hideDropdownMenu();
		} else {
			this.showDropdownMenu();
		}
	}

	showDropdownMenu() {
		this.renderer.addClass(this.dropdownMenu.nativeElement, "show");
	}

	hideDropdownMenu() {
		this.renderer.removeClass(this.dropdownMenu.nativeElement, "show");
	}

	listClientes() {
		this.clienteService.listClientes().subscribe(
			(clientes) => {
				this.clientes = clientes;
			},
			(err) => {
				swalError("Erro ao listar clientes");
			}
		);
	}

	sla(oss) {
		for (var d = 0; d < oss.length; d++) {
			let now;
			if (
				oss[d].data_entrega == null &&
				oss[d].hora_entrega === undefined
			) {
				now = moment(new Date());
			} else {
				const dtFim =
					oss[d].data_entrega.split("T")[0] +
					"T" +
					oss[d].hora_entrega;
				now = moment(dtFim);
			}

			const dtIni =
				oss[d].data_cadastro.split("T")[0] + "T" + oss[d].hora_cadastro;
			const criacao = moment(dtIni);

			const days = now.diff(criacao, "days");
			const hours = now.subtract(days, "days").diff(criacao, "hours");
			const minutes = now
				.subtract(hours, "hours")
				.diff(criacao, "minutes");

			oss[d].sla = "";
			if (days !== 0) {
				oss[d].sla = days + "d ";
			}
			if (hours !== 0) {
				oss[d].sla = oss[d].sla + hours + "h ";
			}
			if (minutes !== 0) {
				oss[d].sla = oss[d].sla + minutes + "m";
			}
		}
	}

	slaAlarme(alarmes) {
		for (var d = 0; d < alarmes.length; d++) {
			const now = moment(new Date());
			const dtIni = alarmes[d].data.split("T")[0] + "T" + alarmes[d].hora;
			const criacao = moment(dtIni);

			const days = now.diff(criacao, "days");
			const hours = now.subtract(days, "days").diff(criacao, "hours");
			const minutes = now
				.subtract(hours, "hours")
				.diff(criacao, "minutes");

			alarmes[d].sla = "";
			if (days !== 0) {
				alarmes[d].sla = days + "d ";
			}
			if (hours !== 0) {
				alarmes[d].sla = alarmes[d].sla + hours + "h ";
			}
			if (minutes !== 0) {
				alarmes[d].sla = alarmes[d].sla + minutes + "m";
			}
		}
	}

	detalhes(ponto) {
		this.pontoService.listPonto(ponto).subscribe((resp) => {
			const pathName = window.location.href.split("#")[1].split("/")[3];
			this.router.navigate(["/painel/" + ponto + "/" + pathName]);
			this.pontoService.ponto(resp[0]);

			let tab;
			if (pathName === "grafico") {
				tab = 2;
			} else if (pathName === "fatura-simulada") {
				tab = 1;
			} else if (pathName === "alerta") {
				tab = 4;
			} else if (pathName === "manejo") {
				tab = 3;
			} else if (pathName === "atendimento-ponto") {
				tab = 5;
			} else if (pathName === "widget") {
				tab = 6;
			} else {
				tab = 0;
			}
			this.painelService.tab(tab);
		});
	}

	getBandeira() {
		this.appService.findBandeira().subscribe(
			(data) => {
				if (data.length > 0) {
					const x = data[0];
					this.bandeira = data[0];
					if (x.bandeira === 1) {
						$("body").addClass("skin-green");
						$("body").removeClass("skin-red");
						$("body").removeClass("skin-yellow");
						$("body").removeClass("skin-red-2");
						$(".labelInfo").addClass("flash1");
					} else if (x.bandeira === 2) {
						$("body").addClass("skin-yellow");
						$("body").removeClass("skin-green");
						$("body").removeClass("skin-red");
						$("body").removeClass("skin-red-2");
						$(".labelInfo").addClass("flash1");
					} else if (x.bandeira === 3) {
						$("body").addClass("skin-red");
						$("body").removeClass("skin-green");
						$("body").removeClass("skin-yellow");
						$("body").removeClass("skin-red-2");
						$(".labelInfo").addClass("flash1Red");
					} else {
						$(".labelInfo").addClass("flash1");
					}
				} else {
					swalInfo("Nenhuma bandeira configurada!");
				}
			},
			(err) => {
				swalError("Erro ao buscar bandeira!");
			}
		);
	}

	whoUser() {
		this.user.nome = window.localStorage.getItem("nome");
		this.user.email = window.localStorage.getItem("email");
	}

	getPermissionItem(x) {
		if (this.perms.findIndex((i) => i.descricao_tela === x) !== -1) {
			return true;
		}
	}

	ngOnInit() {
		this.whoUser();
		this.getBandeira();

		this.pontoService.headerPonto.subscribe((ponto) => {
			const pathName = window.location.href.split("#")[1].split("/")[1];
			if (pathName === "painel") {
				this.escondeHeaderPonto = true;
				this.ponto = ponto;
				this.pontoSelect = ponto.id_ponto;
			} else {
				this.ponto = {};
				this.escondeHeaderPonto = false;
			}
		});

		const timeInterval = setInterval(
			() =>
				(this.date = new Date().toLocaleString("pt-Br", this.options)),
			1000 * 10
		);

		timeInterval;
	}
}
