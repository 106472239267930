import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'horaToDia'
})
export class horaToDiaPipe implements PipeTransform {

  transform(hora : Date): number | string {
    const dia = moment(Date()).diff(hora, 'days');

    return dia >= 1 ? `${dia}d` : moment(hora).format("HH:mm");
  }

}
