export const minToMili = (min: number) => {
	return min * 60 * 1000;
};

export const timeZone: number = new Date().getTimezoneOffset() * 60 * 1000;

export const timeOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

export const dateOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
};

export const fmtDateISO8601 = (date: Date) => {
	const y = date.getFullYear();
	const m = (date.getMonth() + 1).toString().padStart(2, "0");
	const d = date.getDate().toString().padStart(2, "0");
	return `${y}-${m}-${d}`;
};
