import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import { httpOptions } from './../../http';
import 'rxjs/add/operator/map'

@Injectable({
    providedIn: 'root'
})
export class PermissaoService {

    constructor(private http: HttpClient) { }

    userPerms(permissao_grupo): Observable<any> {
        return this.http
            .get(`${environment.api}/permissao-grupo?permissao_grupo_id=${permissao_grupo}`)
            .map( res => res['data'])
    }

    routePerms(permissao_grupo, route: string): Observable<any> {
        return this.http
            .get(`${environment.api}/permissao-tela?permissao_grupo_id=${permissao_grupo}&route=${route}`)
            .map( res => res['data'])
    }

    listAllPerms(): Observable<any[]> {

        return this.http
            .get(`${environment.api}/permissao`)
            .map(response => response['data']);
    }

    listAllPermsGrupo(group_id): Observable<any[]> {
        return this.http
            .get(`${environment.api}/permissao-grupo-all?permissao_grupo_id=${group_id}`)
            .map(response => response['data']);
    }

    listAllPermsTela(): Observable<any[]> {
        return this.http
            .get(`${environment.api}/permissoes-tela`)
            .map(response => response['data']);
    }

    listGrupos(): Observable<any[]> {
        return this.http
            .get(`${environment.api}/permissoes-grupo`)
            .map(response => response['data']);
    }

    savePerms(perms): Observable<any[]> {
        return this.http
            .post(`${environment.api}/permissao`, perms)
            .map(response => response['data']);
    }

    updatePermsTela(perms): Observable<any[]> {
        return this.http
            .put(`${environment.api}/permissao-tela`, perms)
            .map(response => response['data']);
    }

    updatePerms(perms): Observable<any[]> {
        return this.http
            .put(`${environment.api}/permissao`, perms)
            .map(response => response['data']);
    }

    savePermsTela(perms): Observable<any[]> {
        return this.http
            .post(`${environment.api}/permissao-tela`, perms)
            .map(response => response['data']);
    }

    deletePerms(id_permissao_grupo): Observable<any[]> {
        return this.http
            .delete(`${environment.api}/permissao?id_permissao_grupo=` + id_permissao_grupo)
            .map(response => response['data']);
    }

    deletePermsTela(id_permissao_tela): Observable<any[]> {
        return this.http
            .delete(`${environment.api}/permissao-tela?id_permissao_tela=` + id_permissao_tela)
            .map(response => response['data']);
    }

    // SEI LÁ

    // listPermissoes(): Observable<any[]> {
    //     return this.http
    //         .get(`${environment.api}/permissao`, httpOptions)
    //         .map(response => response['data']);
    // }

    // deletePermissao(param): Observable<any[]> {
    //     return this.http
    //         .delete(`${environment.api}/permissao-delete/` + param, httpOptions)
    //         .map(response => response['data']);
    // }

    // savePermissao(param): Observable<any[]> {
    //     return this.http
    //         .post(`${environment.api}/permissao-create`, JSON.stringify(param), httpOptions)
    //         .map(response => response['data']);
    // }

    // acoesTela(id): Observable<any[]> {
    //     return this.http
    //         .post(`${environment.api}/acoes-tela/`, id, httpOptions)
    //         .map(response => response['data']);
    // }

    // acoesTelaById(id): Observable<any[]> {
    //     return this.http
    //         .get(`${environment.api}/acoes-tela-id/` + id, httpOptions)
    //         .map(response => response['data']);
    // }

    // acoes(): Observable<any[]> {
    //     return this.http
    //         .get(`${environment.api}/acoes`, httpOptions)
    //         .map(response => response['data']);
    // }

    // telas(): Observable<any[]> {
    //     return this.http
    //         .get(`${environment.api}/telas`, httpOptions)
    //         .map(response => response['data']);
    // }

    // deletePermissaoTela(param): Observable<any[]> {
    //     return this.http
    //         .delete(`${environment.api}/tela-delete/` + param, httpOptions)
    //         .map(response => response['data']);
    // }

    // savePermissaoTela(param): Observable<any[]> {
    //     return this.http
    //         .post(`${environment.api}/tela-create`, JSON.stringify(param), httpOptions)
    //         .map(response => response['data']);
    // }

    // deletePermissaoAcao(param): Observable<any[]> {
    //     return this.http
    //         .delete(`${environment.api}/acao-delete/` + param, httpOptions)
    //         .map(response => response['data']);
    // }

    // savePermissaoAcao(param): Observable<any[]> {
    //     return this.http
    //         .post(`${environment.api}/acao-create`, JSON.stringify(param), httpOptions)
    //         .map(response => response['data']);
    // }

}
