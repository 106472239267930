import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[appMac]",
})
export class MacDirective {
	private maskPattern =
		/^([A-Fa-f0-9]{0,2})([A-Fa-f0-9]{0,2})([A-Fa-f0-9]{0,2})([A-Fa-f0-9]{0,2})([A-Fa-f0-9]{0,2})([A-Fa-f0-9]{0,2})$/;

	constructor(private el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event: any) {
		let inputValue: string = this.el.nativeElement.value;

		inputValue = inputValue.replace(/[^A-Fa-f0-9]/g, "");

		// if (inputValue.length > 0) {
		this.el.nativeElement.value = this.applyMask(inputValue);
		// }
	}

	private applyMask(value: string): string {
		const formattedValue = value.replace(
			this.maskPattern,
			(match, g1, g2, g3, g4, g5, g6) => {
				let result = g1;
				if (g2) result += ":" + g2;
				if (g3) result += ":" + g3;
				if (g4) result += ":" + g4;
				if (g5) result += ":" + g5;
				if (g6) result += ":" + g6;
				return result;
			}
		);
		return formattedValue;
	}
}
