<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="error-template">
        <h1>
          Oops!</h1>
        <h2>
          404 Not Found</h2>
        <div class="error-details">
          Sorry, an error has occured, Requested page not found!
        </div>
        <div class="error-actions">
          <a href routerLink="/" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
            Take Me Home </a>
          <a target="_blank" href="http://www.genustecnologia.com.br/contato/" class="btn btn-default btn-lg">
            <span class="glyphicon glyphicon-envelope"></span> Contact Support
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
